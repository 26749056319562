<script setup>
import { useDebounceFn, refDebounced } from "@vueuse/core";
import { vScroll } from "@vueuse/components";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";

const { $api } = useNuxtApp();

// ==================== CONSTANTS ====================

const open = inject("searchOpen", false);
const query = ref("");
const currentPage = ref(1);
const search = refDebounced(query, 500);
const data = ref([]);

// ==================== FETCH ====================

const { data: searchData, pending } = $api.get("search", {
  immediate: false,
  server: false,
  query: {
    search: search,
    limit: 12,
    page: currentPage,
  },
  watch: [search, currentPage],
});

// ==================== WATCHERS ====================

watch(searchData, () => {
  if (!searchData.value) return;
  data.value.push(...searchData.value.data);
});

watch(search, () => {
  currentPage.value = 1;
  data.value = [];
});

// ==================== FUNCTIONS ====================

const onScroll = useDebounceFn(({ arrivedState }) => {
  if (arrivedState.bottom && searchData.value.total > data.value.length) {
    currentPage.value++;
  }
}, 200);
</script>

<template>
  <TransitionRoot :show="open" as="template" appear @after-leave="query = ''">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/25 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 h-screen w-screen overflow-hidden p-4 sm:p-6 md:p-20">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="mx-auto max-w-full translate-x-0 rounded-xl bg-white p-2 shadow-2xl ring-1 ring-black/5 transition-all dark:bg-[#0F2260]"
          >
            <div v-auto-animate>
              <input
                class="w-full rounded-md border-0 bg-gray-100 px-6 py-3.5 text-gray-900 focus:ring-0 dark:bg-[#aab1c648] dark:text-white sm:text-xl"
                placeholder="Search..."
                @input="query = $event.target.value"
              />

              <ul
                v-if="search !== ''"
                v-scroll="onScroll"
                class="-mb-2 mt-2 grid max-h-[80vh] scroll-py-2 grid-cols-1 gap-6 overflow-y-auto overflow-x-hidden py-5 text-sm text-gray-800 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
              >
                <template v-if="data?.length > 0">
                  <li
                    v-for="item in data"
                    :key="item._id"
                    :value="item"
                    class="shadow-gradient group relative z-[2] col-span-1 row-span-1 h-full p-1.5"
                    @click="open = false"
                  >
                    <MoleculeCard :nft="item" />
                  </li>
                </template>

                <li v-if="pending" class="relative col-span-full flex size-full items-center justify-center">
                  <IconSpinner class="size-72 p-10" />
                </li>
              </ul>

              <div v-if="search !== '' && data?.length === 0 && !pending" class="px-4 py-14 text-center sm:px-14">
                <p class="mt-4 text-lg text-gray-900 dark:text-white">Not found</p>
              </div>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
