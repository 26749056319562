<script setup>
import { signOut } from "~/services/auth.service";
import { MagnifyingGlassIcon } from "@heroicons/vue/24/solid";
import { Buffer } from "buffer";

const { isMobile } = useDevice();
const { $api } = useNuxtApp();
const $config = useRuntimeConfig();
const notificationStore = useNotificationStore();
const marketplaceStore = useMarketplaceStore();
const userStore = useUserStore();

// ==================== CONSTANTS ====================

const isAuthorized = ref(false);
const isAdmin = ref(false);
const isRefreshing = ref(false);
const openSideBar = ref(false);
const openSearch = ref(false);
const sideBarAction = ref("");
const BSCSCAN_ADDRESS_URL = `https://${
  $config.public.bscScanUrl.includes("testnet") ? "testnet." : ""
}bscscan.com/address`;

const externalLinks = [
  {
    name: "CEEK LAND",
    href: "https://land.ceek.com/",
    icon: resolveComponent("IconCeekLand"),
  },
  {
    name: "CEEK Tokens",
    href: "https://www.ceek.io/exchanges/",
    icon: resolveComponent("IconCeekOutline"),
  },
  {
    name: "Creator Dashboard",
    href: "https://creator.ceek.com/",
    icon: resolveComponent("IconDashboard"),
  },

  {
    name: "CEEK VR Headset",
    href: "https://www.ceekvr.com/vr-headset",
    icon: resolveComponent("IconHeadset"),
  },
  {
    name: "CEEK VR Headphones",
    href: "https://www.ceekvr.com/4dheadphones",
    icon: resolveComponent("IconHeadphones"),
  },
  {
    name: "Sports",
    href: "https://www.ceek.com/discover/category/Sports/Popular/",
    icon: resolveComponent("IconBasketball"),
  },
  {
    name: "CEEK Shop",
    href: "https://ceekvr.com/",
    icon: resolveComponent("IconCart"),
  },
  {
    name: "Download Ceek App",
    href: "https://play.google.com/store/apps/details?id=com.ceek.virtualrealityconcerts",
    icon: resolveComponent("IconAndroid"),
  },
  {
    name: "Download Ceek app",
    href: "https://apps.apple.com/us/app/ceek-virtual-reality/id1169054349",
    icon: resolveComponent("IconApple"),
  },
  {
    name: "Blog",
    href: "https://www.ceek.com/blog/",
    icon: resolveComponent("IconBlog"),
  },
];

// ==================== MOUNT ====================

onMounted(() => {
  isAuthorized.value = userStore.IS_AUTHORIZED;
  isAdmin.value = userStore.IS_SIMPLE_DIMPLE || userStore.IS_BIG_BOSS ? true : false;
});

// ==================== FUNCTIONS ====================

function showSideBar(action = "") {
  openSideBar.value = true;
  sideBarAction.value = action;

  if (action === "user") {
    refreshBalances(false);
  }
}

function hideSideBar() {
  openSideBar.value = false;

  if (sideBarAction.value) {
    sideBarAction.value = "";
  }
}

async function connectMetamask() {
  if (!userStore.IS_AUTHORIZED) {
    notificationStore.ADD({
      type: "error",
      title: `Please login to connect Metamask to your account.`,
    });

    return;
  }

  const provider = "MetaMask";

  try {
    const externalWalletProviders = [...userStore.user.externalWalletProviders];

    if (isMobile) {
      const { data } = await $api.post("user/connect-crypto-wallet-mobile/", { body: { provider } });

      window.location.href = data.value.deepLink;

      notificationStore.ADD({
        type: "success",
        title: "Wallet connection in progress. Please stand by...",
      });

      setTimeout(async () => {
        const { data: wallets } = await $api.get("user/get-crypto-wallets/");

        for (const wallet of wallets.value.providers) {
          if (
            externalWalletProviders.findIndex(
              (item) => item.address.toLowerCase() === wallet.walletAddress.toLowerCase()
            ) === -1
          ) {
            externalWalletProviders.push({ provider: "MetaMask", address: wallet.walletAddress });
          }
        }

        userStore.UPDATE_USER({
          externalWalletProviders,
        });

        notificationStore.ADD({
          type: "success",
          title: "Your MetaMask wallet(s) connected",
        });
      }, 5000);

      return;
    }

    // Here docs for "Ethereum provider API"
    // https://docs.metamask.io/wallet/reference/provider-api/
    const { ethereum } = window;

    if (!ethereum) {
      window.open("https://metamask.io/download/", "_blank");

      return;
    }

    const wallets = await ethereum.request({ method: "eth_requestAccounts" });

    // Do not change the message. On backend same message will be used for signature verification
    const message = "Connect MetaMask wallet to ceek.com";

    const messageHex = `0x${Buffer.from(message, "utf8").toString("hex")}`;

    for (const wallet of wallets) {
      const signedMessage = await ethereum.request({
        method: "personal_sign",
        params: [messageHex, wallet],
      });

      await $api.post("user/connect-crypto-wallet/", { body: { provider, walletAddress: wallet, signedMessage } });

      if (externalWalletProviders.findIndex((item) => item.address.toLowerCase() === wallet.toLowerCase()) === -1) {
        externalWalletProviders.push({ provider: "MetaMask", address: wallet });
      }
    }

    notificationStore.ADD({
      type: "success",
      title: "Your MetaMask wallet(s) connected",
    });

    userStore.UPDATE_USER({
      externalWalletProviders,
    });
  } catch (error) {
    if (error?.code === -32002) {
      notificationStore.ADD({
        type: "alert",
        title:
          "There still pending request for connecting MetaMask wallet. Please use extension to connect wallet or try later",
      });

      await window.ethereum.request({
        method: "wallet_revokePermissions",
        params: [
          {
            eth_accounts: {},
          },
        ],
      });

      return;
    }

    notificationStore.ADD({
      type: "alert",
      title: error?.code === 4001 ? "You denied message signature" : "Can't connect MetaMask wallet ...",
    });
  }
}

async function refreshBalances(showNotification = true) {
  isRefreshing.value = true;

  const { data, error } = await $api.post("user/update-balance");

  if (error?.value || data.value?.error) {
    isRefreshing.value = false;

    return;
  }

  if (data.value.balance) {
    userStore.UPDATE_USER({
      balanceBNB: data.value.balance.BNB,
      BSCTokens: data.value.balance.CEEK,
    });
  }

  isRefreshing.value = false;

  if (showNotification) {
    notificationStore.ADD({
      type: "success",
      title: "Wallet info updated",
    });
  }
}

// ==================== PROVIDES ====================

provide("searchOpen", openSearch);
</script>

<template>
  <header class="relative overflow-hidden bg-white text-sm shadow-lg dark:bg-the-header lg:text-lg">
    <div class="rectangle-4077" />
    <div class="rectangle-4078" />
    <div class="relative">
      <nav
        class="mx-auto flex w-full max-w-[1920px] items-center justify-between px-6 py-2 lg:px-11"
        aria-label="Global"
      >
        <div class="flex items-center gap-x-9">
          <NuxtLink
            to="/"
            class="-m-1.5 size-16 p-1.5 transition-all hover:drop-shadow-black dark:hover:drop-shadow-white"
          >
            <span class="sr-only">CEEK</span>
            <img src="/img/NFT_CEEK_logo-sm.webp" class="size-full object-cover" alt="CEEK Logo" />
          </NuxtLink>
          <div class="hidden items-center lg:flex lg:gap-x-4">
            <NuxtLink to="/explore">
              <MoleculeButtonGradientOutline class="px-10 py-2.5" aria-label="Explore"
                >Explore</MoleculeButtonGradientOutline
              >
            </NuxtLink>
            <NuxtLink to="/create">
              <MoleculeButtonGradientOutline class="px-10 py-2.5" aria-label="Create"
                >Create</MoleculeButtonGradientOutline
              >
            </NuxtLink>
          </div>
        </div>
        <div class="flex items-center gap-6">
          <AtomButton class="order-1 transition active:scale-90" aria-label="Metamask">
            <IconMetamask class="size-6" @click="connectMetamask" />
          </AtomButton>
          <ClientOnly>
            <MagnifyingGlassIcon
              class="order-1 -m-1 size-8 shrink-0 cursor-pointer fill-black stroke-gray-500 p-1 transition hover:scale-125 dark:fill-white dark:stroke-white/50"
              @click="openSearch = !openSearch"
            />
            <MoleculeThemeSwitcher class="order-0 invisible flex !w-0 sm:visible sm:order-1 sm:!w-24" />
          </ClientOnly>
          <AtomButton
            aria-label="External Links"
            type="button"
            class="order-1 -m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 duration-0 dark:text-white"
            @click="showSideBar('external')"
          >
            <span class="sr-only">Open main menu</span>
            <IconBars3 class="size-8" aria-hidden="true"></IconBars3>
          </AtomButton>
          <MoleculeClientWrapper>
            <template #body>
              <template v-if="!isAuthorized">
                <div class="order-1 hidden items-center gap-4 sm:flex">
                  <NuxtLink
                    to="/sign-in"
                    class="custom-gradient relative size-fit rounded-full px-10 py-2.5 text-sm transition-all hover:shadow-around hover:shadow-black/25 active:scale-90 dark:text-white dark:hover:shadow-[#00bbba] lg:text-xl"
                    aria-label="Log In"
                  >
                    Log In
                  </NuxtLink>
                  <NuxtLink
                    to="/sign-up"
                    class="relative size-fit rounded-full bg-primary-gradient px-10 py-2.5 text-sm text-white transition-all hover:shadow-around hover:shadow-black/25 active:scale-90 dark:hover:shadow-[#00bbba] lg:text-xl"
                    aria-label="Sign Up"
                  >
                    Sign Up
                  </NuxtLink>
                </div>
                <NuxtLink
                  to="/sign-in"
                  class="custom-gradient relative order-1 flex size-12 items-center justify-center rounded-full transition-shadow hover:shadow-around hover:shadow-black/25 dark:hover:shadow-[#00bbba] sm:hidden"
                  type="button"
                  aria-label="Sign In"
                >
                  <IconUserGradient class="size-7" />
                </NuxtLink>
              </template>
              <template v-else>
                <AtomButton aria-label="Sidebar" class="order-1 shrink-0" type="button" @click="showSideBar('user')">
                  <div
                    v-if="!userStore.user.avatar"
                    class="custom-gradient relative flex size-12 items-center justify-center rounded-full transition-shadow hover:shadow-around hover:shadow-black/25 dark:hover:shadow-[#00bbba]"
                  >
                    <IconUserGradient class="size-7" />
                  </div>
                  <AtomImageNuxtImage
                    v-else
                    class="inline-block aspect-square size-12 rounded-full object-cover transition-shadow hover:shadow-around hover:shadow-black/25 dark:hover:shadow-[#00bbba]"
                    resize="48x48"
                    bucket-alias="avatars"
                    :src="userStore.user.avatar"
                    :alt="`avatar ${userStore.user.fullName || userStore.user.userName}`"
                  />
                </AtomButton>
              </template>
            </template>
            <template #skeleton>
              <div class="order-1 size-12 animate-pulse rounded-full bg-gray-300 dark:bg-[#2D059E]"></div>
            </template>
          </MoleculeClientWrapper>
        </div>
      </nav>
      <MoleculeSideBar :open="openSideBar" @close-side-bar="hideSideBar">
        <template v-if="sideBarAction === 'external'">
          <div class="space-y-2">
            <ClientOnly>
              <MoleculeThemeSwitcher
                class="visible mx-auto mb-7 flex before:bg-blue-primary before:bg-none hover:shadow-around hover:shadow-black/25 dark:before:bg-white dark:hover:shadow-[#00bbba] sm:invisible sm:mb-0 sm:h-0"
              />
            </ClientOnly>
            <NuxtLink
              v-for="link in externalLinks"
              :key="link.name"
              :to="link.href"
              target="_blank"
              rel="noopener"
              class="group flex items-center gap-2 rounded-xl border-2 border-blue-primary px-4 py-2 text-base font-bold uppercase text-blue-primary hover:bg-blue-primary hover:text-white hover:shadow-[0_0_10px_rgb(101,138,250)] hover:shadow-blue-primary dark:border-white dark:text-white dark:hover:bg-white dark:hover:text-blue-primary"
            >
              <component
                :is="link.icon"
                class="group-hover:[&>circle]:stroke-white dark:[&>circle]:stroke-white dark:group-hover:[&>circle]:stroke-blue-primary group-hover:[&>path]:fill-white dark:[&>path]:fill-white dark:group-hover:[&>path]:fill-blue-primary"
              />
              <p class="duration-0">{{ link.name }}</p></NuxtLink
            >
          </div>
        </template>
        <template v-if="sideBarAction === 'user'">
          <div>
            <div class="relative z-10 mx-auto w-fit rounded-full bg-primary-gradient p-1">
              <div
                v-if="!userStore.user?.avatar"
                class="flex size-28 items-center justify-center rounded-full bg-white dark:bg-[#0F2260]"
              >
                <IconUserGradient class="size-20" />
              </div>
              <AtomImageNuxtImage
                v-else
                :src="userStore.user?.avatar"
                :alt="`avatar ${userStore.user.fullName || userStore.user.userName}`"
                resize="112x112"
                bucket-alias="avatars"
                class="mx-auto size-28 rounded-full object-cover"
              />
              <span v-if="userStore.user.verify" class="absolute -left-3 -top-5">
                <IconCrown2 class="size-12" />
              </span>
            </div>
            <p
              v-if="userStore.user.fullName"
              class="mx-auto mt-2 w-fit text-2xl font-bold text-black/60 dark:text-white"
            >
              {{ userStore.user.fullName }}
            </p>
            <p class="mx-auto mt-1 w-fit text-base font-normal dark:text-white">@{{ userStore.user.userName }}</p>
            <div class="mt-4 flex justify-between">
              <div class="flex gap-2">
                <IconWallet class="mt-0.5 size-6" />
                <p
                  class="flex items-center gap-2 bg-primary-gradient bg-clip-text text-lg font-medium text-transparent"
                >
                  CEEK
                  <span class="text-sm font-medium text-[#FFB629]">Active</span>
                </p>
              </div>
              <div
                class="flex max-w-[200px] items-center gap-2 bg-primary-gradient bg-clip-text text-sm font-semibold text-transparent"
              >
                <NuxtLink
                  :to="`${BSCSCAN_ADDRESS_URL}/${userStore.user.BSCWalletAddress}`"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="truncate"
                  >{{ userStore.user.BSCWalletAddress.slice(0, 6) }}...{{
                    userStore.user.BSCWalletAddress.slice(-4)
                  }}</NuxtLink
                ><AtomButton
                  aria-label="Copy Text"
                  class="h-fit"
                  type="button"
                  @click="copyText(notificationStore, userStore.user.BSCWalletAddress)"
                >
                  <IconCopyGradient class="size-6" />
                </AtomButton>
                <AtomButton
                  aria-label="Update Wallet Info"
                  :class="[
                    'h-fit',
                    {
                      'pointer-events-none': isRefreshing,
                    },
                  ]"
                  type="button"
                  @click="refreshBalances()"
                >
                  <IconRefresh
                    :class="[
                      'size-6 shrink-0',
                      {
                        'animate-spin': isRefreshing,
                      },
                    ]"
                  />
                </AtomButton>
              </div>
            </div>
            <div
              v-for="item in userStore.user.externalWalletProviders"
              :key="item._id"
              class="mt-4 flex justify-between"
            >
              <div class="flex gap-2">
                <IconWallet class="mt-0.5 size-6" />
                <p class="bg-primary-gradient bg-clip-text text-lg font-medium text-transparent">{{ item.provider }}</p>
              </div>
              <div
                class="flex max-w-[200px] gap-2 bg-primary-gradient bg-clip-text text-sm font-semibold text-transparent"
              >
                <NuxtLink
                  :to="`${BSCSCAN_ADDRESS_URL}/${item.address}`"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="truncate"
                  >{{ item.address.slice(0, 6) }}...{{ item.address.slice(-4) }}</NuxtLink
                >
                <AtomButton
                  aria-label="Copy Text"
                  class="h-fit"
                  type="button"
                  @click="copyText(notificationStore, item.address)"
                >
                  <IconCopyGradient class="size-6" />
                </AtomButton>
              </div>
            </div>
          </div>
          <div class="my-3 rounded-xl px-4 py-3 shadow-around shadow-black/20 dark:bg-[#2D059E]">
            <p class="text-lg font-medium text-black/60 dark:text-white">Available</p>
            <div class="flex items-center gap-3 border-b-2 border-[#45BFE4]/20 py-3">
              <img
                class="mt-1 aspect-square size-5 self-start object-cover opacity-0 transition"
                src="/img/ceek-coin.webp"
                alt="CEEK coin"
                onload="this.onload=null;this.classList.remove('opacity-0');"
              />
              <div class="flex w-full flex-col">
                <div class="flex justify-between">
                  <span
                    class="line-clamp-1 bg-gradient-blue bg-clip-text text-lg font-bold text-transparent dark:text-white"
                    >{{ userStore.user.BSCTokens.toFixed(2) }} CEEK</span
                  >
                  <NuxtLink
                    class="shrink-0 rounded-full transition-all hover:shadow-around hover:shadow-black/25 active:scale-90 dark:hover:shadow-[#00bbba]"
                    :to="`https://${
                      $config.public.env === 'development' ? 'test.ceek.com' : 'www.ceek.com'
                    }/buy-token/`"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span
                      class="size-fit rounded-full bg-gradient-blue p-2 px-3 text-xs uppercase text-white dark:bg-[#5DA6F6] dark:bg-none"
                      aria-label="Buy CEEK"
                      >Buy CEEK</span
                    >
                  </NuxtLink>
                </div>

                <span class="truncate text-base font-bold text-[#9288E0]"
                  >$ {{ (userStore.user.BSCTokens * marketplaceStore.GET_EXCHANGE_RATE.tokenPrice).toFixed(2) }}</span
                >
              </div>
            </div>
            <div class="flex items-center gap-3 pt-3">
              <IconBNB class="mt-1 aspect-square size-5 self-start" />
              <div class="mr-auto flex flex-col">
                <span class="truncate text-lg font-bold text-[#45BFE4] dark:text-white"
                  >{{ userStore.user.balanceBNB.toFixed(9) }} BNB</span
                >
                <span class="truncate text-base font-bold text-[#9288E0]"
                  >$ {{ (userStore.user.balanceBNB * marketplaceStore.GET_EXCHANGE_RATE.bnbPrice).toFixed(2) }}</span
                >
              </div>
            </div>
          </div>

          <div class="my-3 rounded-xl p-3 shadow-around shadow-black/20 dark:bg-[#2D059E]">
            <div v-if="isAdmin" class="border-b-2 border-[#45BFE4]/20 pb-3">
              <NuxtLink
                to="/cms"
                class="group flex gap-3 rounded-xl bg-transparent px-4 py-2 hover:bg-blue-primary active:scale-90 dark:hover:bg-white"
              >
                <IconAdmin
                  class="size-6 group-hover:fill-white dark:fill-white dark:group-hover:fill-[url(#admin-gradient)]"
                />
                <span
                  class="text-base font-bold uppercase text-black group-hover:text-white group-[.active-sidebar]:text-white dark:text-white dark:group-hover:text-black dark:group-[.active-sidebar]:text-black"
                >
                  {{ userStore.IS_BIG_BOSS ? "SUPER ADMIN" : "ADMIN" }}
                </span>
              </NuxtLink>
            </div>
            <div :class="[isAdmin ? 'py-3' : 'pb-3 pt-0', 'border-b-2 border-[#45BFE4]/20']">
              <NuxtLink
                to="/transactions"
                active-class="active-sidebar"
                class="group flex gap-3 rounded-xl bg-transparent px-4 py-2 hover:bg-blue-primary active:scale-90 dark:hover:bg-white"
                @click="openSideBar = false"
              >
                <IconTransaction
                  class="size-6 group-hover:fill-white group-[.active-sidebar]:fill-white dark:fill-white dark:group-hover:fill-[url(#transaction-gradient)] dark:group-[.active-sidebar]:fill-[url(#transaction-gradient)]"
                />
                <span
                  class="text-base font-bold uppercase text-black group-hover:text-white group-[.active-sidebar]:text-white dark:text-white dark:group-hover:text-black dark:group-[.active-sidebar]:text-black"
                >
                  MY TRANSACTIONS
                </span>
              </NuxtLink>
            </div>
            <div class="border-b-2 border-[#45BFE4]/20 py-3">
              <NuxtLink
                to="/profile"
                active-class="active-sidebar"
                class="group flex gap-3 rounded-xl bg-transparent px-4 py-2 hover:bg-blue-primary active:scale-90 dark:hover:bg-white"
                @click="openSideBar = false"
              >
                <IconUserGradient
                  class="size-6 group-hover:fill-white group-[.active-sidebar]:fill-white dark:fill-white dark:group-hover:fill-[url(#user-gradient)] dark:group-[.active-sidebar]:fill-[url(#user-gradient)]"
                />
                <span
                  class="text-base font-bold uppercase text-black group-hover:text-white group-[.active-sidebar]:text-white dark:text-white dark:group-hover:text-black dark:group-[.active-sidebar]:text-black"
                >
                  MY ACCOUNT
                </span>
              </NuxtLink>
            </div>
            <div class="pt-3">
              <NuxtLink
                to="/my-items/"
                active-class="active-sidebar"
                class="group flex gap-3 rounded-xl bg-transparent px-4 py-2 hover:bg-blue-primary active:scale-90 dark:hover:bg-white"
                @click="openSideBar = false"
              >
                <IconAssets
                  class="size-6 group-hover:fill-white group-[.active-sidebar]:fill-white dark:fill-white dark:group-hover:fill-[url(#assets-gradient)] dark:group-[.active-sidebar]:fill-[url(#assets-gradient)]"
                />
                <span
                  class="text-base font-bold uppercase text-black group-hover:text-white group-[.active-sidebar]:text-white dark:text-white dark:group-hover:text-black dark:group-[.active-sidebar]:text-black"
                >
                  MY ITEMS
                </span>
              </NuxtLink>
            </div>
          </div>
          <MoleculeButtonGradient
            aria-label="Logout"
            type="button"
            class="flex w-full items-center justify-center gap-2 rounded-xl p-2.5 text-lg font-semibold md:text-xl"
            @click="signOut($api, userStore)"
          >
            LOG OUT
            <IconLogOut class="size-6" />
          </MoleculeButtonGradient>
        </template>
      </MoleculeSideBar>
    </div>
    <Teleport to="body">
      <SICTheSearch />
    </Teleport>
  </header>
</template>
