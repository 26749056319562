<template>
  <svg width="24" height="24" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M396.82 148.6L446.04 170.27C451.49 172.67 455 178.05 455 184V416C455 421.95 451.49 427.33 446.04 429.73L262.04 510.73C260.12 511.58 258.06 512 256 512C253.94 512 251.88 511.58 249.96 510.73L65.96 429.73C60.51 427.33 57 421.95 57 416V184C57 178.05 60.51 172.67 65.96 170.27L115.17 148.61L115.52 150.86C118.93 172.96 137.64 189 160 189H352C374.36 189 393.07 172.96 396.48 150.84L396.82 148.6ZM193 385.857L268.6 327.19H361L285.4 411H176.2L151 318.81L226.6 235H327.4L193 385.857Z"
      fill="#0D00FF"
    />
    <path
      d="M145.17 146.28C146.3 153.6 152.6 159 160 159H352C359.4 159 365.7 153.6 366.83 146.28L382.83 42.28C383.7 36.59 381.24 30.9 376.5 27.64C371.75 24.38 365.56 24.12 360.56 26.98L314.6 53.24L266.7 4.49C263.88 1.62 260.02 0 256 0C251.98 0 248.12 1.62 245.3 4.49L197.4 53.24L151.44 26.98C146.44 24.12 140.25 24.38 135.5 27.64C130.76 30.9 128.3 36.59 129.17 42.28L145.17 146.28Z"
      fill="#0D00FF"
    />
  </svg>
</template>
